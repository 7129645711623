
import { iframeVideoDismiss, iframeVideoLoad } from './iframe-video'

// VIDEO CONTROLS
export function initializeRouting (kbps) {
  const $pageWrapper = document.getElementById('page-wrapper')
  const $backgroundVideo = document.getElementById('background-video')
  function onRoute (event) {
    console.log('onRoute: ' + window.location.hash)
    const $wrapper = document.getElementById('wrapper')
    switch (window.location.hash) {
      case '#movie': {
        iframeVideoLoad(kbps)
        $backgroundVideo.classList.add('blur')
        $pageWrapper.classList.add('defocus-active')
        document.body.classList.add('noscroll')
        if ($backgroundVideo.src) {
          $backgroundVideo.pause()
        }
        setTimeout(() => {
          const $wrapper = document.getElementById('wrapper')
          $wrapper.classList.add('defocus-active')
          // Disable window scroll
        }, 100)
        return
      }
      case '#':
      case '': {
        // Home anchore restores main screen and disposes of movie player.
        if ($backgroundVideo.src) {
          $backgroundVideo.play()
        }
        $backgroundVideo.classList.remove('blur')

        if ($wrapper.classList.contains('defocus-active')) {
          $wrapper.classList.remove('defocus-active')
          document.body.classList.remove('noscroll')
        }
        const $pageWrapper = document.getElementById('page-wrapper')
        $pageWrapper.classList.remove('defocus-active')
        iframeVideoDismiss()
      }
    };
  }
  window.onhashchange = onRoute
  onRoute()
}
