/* global getComputedStyle */

import './countdown.css'

// Some fonts have a crossbar line through zero. In this
// case swap it with O which does not.
const SWAP_ZERO_FOR_O = true

function countdownSetWidth (width) {
  document.documentElement.style.setProperty('--countdown-width', width)
}

export function countdownSetPlay (play) {
  [
    'countdown-border-box',
    'countdown-content-box',
    'countdown-vert-sep-1',
    'countdown-vert-sep-2',
    'countdown-vert-sep-3',
    'countdown-days',
    'countdown-days-label',
    'countdown-hours',
    'countdown-hours-label',
    'countdown-mins',
    'countdown-mins-label',
    'countdown-secs',
    'countdown-secs-label'
  ].forEach(element => {
    // For each element, set the animation-play-state to play
    document.getElementById(element).style.animationPlayState = play ? 'running' : 'paused'
  })
}

export function countdownInit (countdownOptions) {
  // set visibility to visible
  if (countdownOptions.width !== undefined) {
    countdownSetWidth(countdownOptions.width)
  }
  if (countdownOptions.autoplay === undefined || countdownOptions.autoplay) {
    countdownSetPlay(true)
  }
  function updateCountdownText () {
    const now = new Date()
    const date = countdownOptions.futureDate || new Date(2022, 8, 1)
    // Get the difference in days, hours, minutes and seconds between the two dates.
    const currSecondsRemaining = Number.parseInt((date.getTime() - now.getTime()) / 1000)
    let secondsRemaining = currSecondsRemaining
    const days = Math.floor(secondsRemaining / 86400)
    secondsRemaining -= days * 86400
    const hours = Math.floor(secondsRemaining / 3600)
    secondsRemaining -= hours * 3600
    const minutes = Math.floor(secondsRemaining / 60)
    secondsRemaining -= minutes * 60
    function pad (num) {
      // Pad with leading zeros
      return (num < 10 ? '0' : '') + num
    }
    const newVals = {
      'countdown-days': pad(days),
      'countdown-hours': pad(hours),
      'countdown-mins': pad(minutes),
      'countdown-secs': pad(secondsRemaining)
    }
    for (const [key, value] of Object.entries(newVals)) {
      const dom = document.getElementById(key)
      if (Number.parseInt(dom.innerText) !== Number.parseInt(value)) {
        let innerText = value
        if (SWAP_ZERO_FOR_O) {
          innerText = innerText.replace(/0/g, 'O')
        }
        dom.innerText = innerText
      }
    }
  }
  function animate () {
    // Do whatever
    updateCountdownText()
    // Get the position of the target element.
    if (countdownOptions.targetElement) {
      // Get target scroll position.
      const rect = countdownOptions.targetElement.getBoundingClientRect()
      const y1 = Math.round(rect.top + document.documentElement.scrollTop)
      const x1 = Math.round(rect.left + document.documentElement.scrollLeft)
      // set the global css variable
      function setCssVarIfChanged (cssVarName, value) {
        const cssVarValue = getComputedStyle(document.documentElement).getPropertyValue(cssVarName)
        if (cssVarValue !== value) {
          document.documentElement.style.setProperty(cssVarName, value)
        }
      }
      setCssVarIfChanged('--countdown-x', x1 + 'px')
      setCssVarIfChanged('--countdown-y', y1 + 'px')
    }
    window.requestAnimationFrame(animate)
  }

  // Make sure that adding the animation function is only done once.
  let once = true
  function firstRun () {
    if (once) {
      once = false
      return true
    } else {
      return false
    }
  }
  if (firstRun()) {
    window.requestAnimationFrame(animate)
  }
}
