
import './page-donation.css'
import htmlFrag from './page-donation.frag.html'
import { loadHtmlFragment } from './load-html-fragment'

const INTERVAL_TIME = 3500

export function initPageDonation () {
  loadHtmlFragment('#section-donation', htmlFrag)
  // get the raised amount
  const url = 'https://give-send-go-maryam.onrender.com/get'
  fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.raised === '') {
        return
      }
      const $divRaised = document.querySelector('#div-raised')
      $divRaised.innerHTML = `<p><strong>HELP US FUND THIS FILM!</strong></p><p><strong>Raised: <span style="color: lightgreen;">${data.raised}</span></strong> with <strong>${data.donors} donors</strong><br><strong>Goal: <span style="color: tomato;">${data.goal}</span></strong><br></p>`
      const recentDonations = data.recent_donations
      // random shuffle recentDonations
      for (let i = recentDonations.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = recentDonations[i]
        recentDonations[i] = recentDonations[j]
        recentDonations[j] = temp
      }
      if (!recentDonations) {
        return
      }
      const $divRecentDonations = document.querySelector('#donation-testimonies')
      let commentIndex = 0
      function nextComment () {
        console.log('nextComment', commentIndex)
        if (recentDonations.length === 0) {
          return
        }
        if (commentIndex >= recentDonations.length) {
          commentIndex = 0
        }
        const donation = recentDonations[commentIndex]
        const name = donation.name
        const amount = donation.amount
        const comment = donation.comment ? `"<i>${donation.comment}</i>"` : ''
        const when = donation.when
        const text = `<p style="height: 2em;"><strong>${name}</strong> donated <strong style="color: lightgreen;">${amount}</strong>, <strong>${when}</strong><br>${comment}</p>`
        $divRecentDonations.innerHTML = text
        commentIndex++
        $divRecentDonations.classList.add('show')
        const extraTime = Math.max(0, comment.length - 1) * 50
        const intervalTime = INTERVAL_TIME + extraTime
        setTimeout(() => {
          $divRecentDonations.classList.remove('show')
        }, intervalTime - 700)
        setTimeout(nextComment, intervalTime)
      }
      nextComment() // start the loop.
    })
}
