
export function loadBackgroundVideo (kbps) {
  const videos = {
    1080: 'images/hero-1080.mp4',
    720: 'images/hero-720.mp4',
    480: 'images/hero-480.mp4'
  }
  let url = null
  if (kbps > 400) {
    console.log('High bandwidth user')
    url = videos['1080']
  } else if (kbps > 60) {
    console.log('Low bandwidth user')
    url = videos['720']
  } else {
    console.log('Ultra Low bandwidth user')
    url = videos['480']
  }
  // const url = "images/banner_720_34.mp4"
  console.log('Fetching background video: ' + url)
  const $backgroundVideo = document.getElementById('background-video')
  if ($backgroundVideo) {
    $backgroundVideo.src = url
    const playPromise = $backgroundVideo.play()
    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
        // Show playing UI.
        console.log('Automatic playback started!')
      })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
          console.log('Auto-play was prevented: ' + error)
        })
    }
  } else {
    console.log('background-video not found')
  }
}
