
export function loadHtmlFragment (querySelector, htmlFragmentString) {
  const $target = document.querySelector(querySelector)
  // Generate a div and populate it with the string from the fragment.
  const $div = document.createElement('div')
  $div.innerHTML = htmlFragmentString
  // Now get the signup html
  const $signup = $div.querySelector(querySelector)
  // Now replace the target with the fragment.
  $target.replaceWith($signup)
}
