
import './iframe-video.css'

const $iframeMovieContainer = document.getElementById('iframe-movie-container')
const $iframeMovie = document.getElementById('iframe-movie')
const $frameMovieCloseBtn = document.getElementById('iframe-movie-close-button')
let jobMovieCloseButton = null
let jobClearMovie = null

export function initFrameVideo () {
  $frameMovieCloseBtn.style.display = 'block'
}

export function iframeVideoLoad (kbps) {
  // get password from the cookies
  clearTimeout(jobMovieCloseButton)
  clearTimeout(jobClearMovie)
  $iframeMovieContainer.style.display = 'block'
  jobMovieCloseButton = null
  jobClearMovie = null
  $iframeMovie.src = 'movie' + '/index.html?kbps=' + kbps
  console.log($iframeMovie.src)
  $iframeMovie.style.display = 'block'
  $iframeMovie.style.visibility = 'hidden'
  $iframeMovie.style.visibility = 'visible'
  $iframeMovie.classList.add('active')

  // #iframe-movie-close-button
  jobMovieCloseButton = setTimeout(() => {
    $frameMovieCloseBtn.classList.add('active')
    clearTimeout(jobMovieCloseButton)
    jobMovieCloseButton = null
  }, 1000)
}

export function iframeVideoDismiss () {
  clearTimeout(jobMovieCloseButton)
  clearTimeout(jobClearMovie)
  jobMovieCloseButton = null
  jobClearMovie = null
  // Home anchore restores main screen and disposes of movie player.
  // $iframeMovie.style.display = 'none'
  $frameMovieCloseBtn.classList.remove('active')
  jobClearMovie = setTimeout(() => {
    $iframeMovie.src = ''
    clearTimeout(jobClearMovie)
    jobClearMovie = null
  }, 700)
  clearTimeout(jobMovieCloseButton)
  $iframeMovie.classList.remove('active')
  // $iframeMovie.classList.remove('active')
}
