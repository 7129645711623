
/* global gtag */

import { initLazyImageLoader } from './lazy-image-loader'
import { speedTest } from './speed-test'
import { initializeRouting } from './routing'
import { loadBackgroundVideo } from './background-video'
import { initFrameVideo } from './iframe-video'
import { cryptoXor, hashpass } from './crypto-xor'
import { initPageDonation } from './page-donation'
import { initPageAbout } from './page-about'
import { initPageFooter } from './page-footer'
import { initSignupForm } from './page-signup'
import { countdownInit, countdownSetPlay } from './countdown'

const ENABLE_PAGE_HEALTHCARE = true

function initWindowClickListener () {
  document.body.addEventListener('click', function (e) {
    // If the video is currently playing and the user clicks anywhere on the page outside
    // of the iframe then stop and tear down the video.
    if (window.location.hash === '#movie') {
      window.location.hash = ''
    }
  })
}

function enableNewContent () {
  // url search params for d=1
  if (window.location.search.includes('d=1')) {
    return true
  }
  return ENABLE_PAGE_HEALTHCARE
}

function initAttachAnalyticsTracking () {
  const leads = document.getElementsByClassName('generates_lead')
  for (let i = 0; i < leads.length; i++) {
    // console.log("Tracking lead in ", leads[i]);
    const tagName = leads[i].tagName
    function trackGenerateLead (evt) {
      gtag('event', 'generate_lead', { tag_name: tagName, which: i })
    }
    leads[i].addEventListener('click', trackGenerateLead)
  }
}

function initApp () {
  globalThis.cryptoXor = cryptoXor
  globalThis.hashpass = hashpass
  initFrameVideo()
  speedTest((kbps) => {
    console.log('Your connection is', kbps, 'kbps')
    initializeRouting(kbps)
    loadBackgroundVideo(kbps)
  })
  if (enableNewContent()) {
    initPageDonation()
    initPageAbout()
    initPageFooter()
  }
  initLazyImageLoader()
  initWindowClickListener()
  initAttachAnalyticsTracking()
  initSignupForm()
  // Create a date object for the current date and time
  // Create a fute date that is 29 days from now
  // generate a random number
  const future = new Date()
  // set future to January 15, 2023
  future.setMonth(0)
  future.setDate(16)
  future.setFullYear(2023)

  countdownInit({
    futureDate: future,
    targetElement: document.getElementById('targetAnchor'),
    width: undefined,
    autoplay: false
  })
  setTimeout(() => {
    countdownSetPlay(true)
  }, 5000)
  console.log('Georgefloyddocumentary.com')
}

export { initApp }
