
import './page-signup.css'
// import { pageWrapperSetFocus } from './page-wrapper'
import signupFrag from './page-signup.frag.html'

let signupActive = false

// Note, do not use directly, this is for router only.
export function signupSetActive (on) {
  const $signup = document.querySelector('#signup')
  if (on) {
    $signup.classList.add('active')
    // pageWrapperSetFocus(false)
  } else {
    $signup.classList.remove('active')
    // pageWrapperSetFocus(true)
  }
  signupActive = on
}

export function signupIsActive () {
  return signupActive
}

export function initSignupForm () {
  if (!signupFrag) {
    globalThis.alert('signup html not found')
  }
  const $target = document.getElementById('signup')
  if (!$target) {
    globalThis.alert('signup target not found')
    return
  }
  // Generate a div and populate it with the string from the fragment.
  const $div = document.createElement('div')
  $div.innerHTML = signupFrag
  // Now get the signup html
  const $signup = $div.querySelector('#signup')
  // Now replace the target with the fragment.
  $target.replaceWith($signup)

  const $btn = document.getElementById('signup-close-btn')
  $btn.addEventListener('click', () => {
    globalThis.alert('signup clicked')
  })

  signupSetActive(true)
}
