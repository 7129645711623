/* global XMLHttpRequest */

// startup time
export function speedTest (cb) {
  // Let's initialize the primitives
  // Set up the AJAX to perform
  const xhr = new XMLHttpRequest()
  const rando = Number.parseInt(Math.floor(Math.random() * 10000000))
  const imgUrl = 'speed_test.jpg' + '?random=' + rando
  const startTime = (new Date()).getTime()
  // Rig the call-back... THE important part
  xhr.onreadystatechange = () => {
    // we only need to know when the request has completed
    if (xhr.readyState === 4 && xhr.status === 200) {
      // Here we stop the timer & register end time
      const endTime = (new Date()).getTime()
      // Also, calculate the file-size which has transferred
      const fileSize = xhr.responseText.length
      // N.B: fileSize reports number of Bytes
      // Calculate the connection-speed
      const speed = parseInt(fileSize / ((endTime - startTime) / 1000) / 1024, 10)
      // Use (fileSize * 8) instead of fileSize for kBps instead of kbps
      // Report the result, or have fries with it...
      console.log('Your speed: ' + speed + ' kbps, url: ' + imgUrl)
      cb(speed)
    }
  }
  // Snap back; here's where we start the timer
  // All set, let's hit it!
  xhr.open('GET', imgUrl, true)
  xhr.send()
}
